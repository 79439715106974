import React from 'react';
import Home from '../Home'
const App: React.FC = () => {
  return (
        <>
          <Home/>
        </>
  );
}

export default App;
