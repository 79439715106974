import React from 'react';
import Footer from '../../components/Footer';
import styled from 'styled-components';
import Github from '../../assets/icons/github.js'
import LinkedIn from '../../assets/icons/linkedin.js'

const customMediaQuery = (maxWidth: number) =>
      `@media (max-width: ${maxWidth}px)`;

const media = {
      custom: customMediaQuery,
      desktop: customMediaQuery(922),
      tablet: customMediaQuery(768),
      phone: customMediaQuery(576),
};


const HomeWrapper = styled.div`
      background: linear-gradient(to right, #000, #333);
      height: 100vh;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-items: center;

`

const Container = styled.div`
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      align-items: center;
      ${media.desktop} {
            display: flex;
            flex-flow: row wrap;
      }
      ${media.tablet} {
            display: flex;
            flex-flow: column;
      }
      ${media.phone} {
            display: flex;
            flex-flow: column;
      }
`

const H1 = styled.h1`
      color: #FFF;
      font-size: 45px;
      margin: 0;
      font-weight: 900;
      `
const H2 = styled.h2`
      color: #FFF;
      margin: 0;
      font-weight: bold;
`
const Description = styled.div`
      padding: 0 20px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 300px;

      `

const Avatar = styled.div`
      padding: 0 20px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      max-width: 284px;
            p {
                  color: white;
                  text-align: left;
                  font-size: 15px;
                  letter-spacing: 2px;
            }

      `

const SocialMediaSection = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      `
const SocialButton = styled.a`
      display: flex;
      align-items: center;
      padding: 10px;
      background: transparent;
      text-decoration: none;
      color: white;
      border-radius: 25px;
      margin: 5px;
      transition: background 500ms ease-in-out;

      &:hover,
      &:focus {
      background: black;
      }
`

const Icon = styled.div`
      padding: 0 5px;
`

const Home: React.FC = () => {
      return (
            <HomeWrapper>
                  <Container>
                        <Description>
                              <H2>OH HI, IT'S ME</H2>
                              <H1>GUNTUR</H1>
                              <H1>KURNIAWAN</H1>
                              <H1>HERYANTO</H1>
                        <SocialMediaSection>
                              <SocialButton href="https://github.com/gunturkh" target="_blank" rel="noopener" >
                                    <Icon>
                                          <Github/>
                                    </Icon>
                                    Github
                              </SocialButton>
                              <SocialButton href="https://linkedin.com/in/gunturkh" target="_blank" rel="noopener" >
                                    <Icon>
                                          <LinkedIn/>
                                    </Icon>
                                    LinkedIn
                              </SocialButton>
                        </SocialMediaSection>
                        </Description>
                        <Avatar>
                              <p>I'm an experienced Frontend Web Developer with a demonstrated history of working in the tech startup focusing in transportation industry.</p>
                              <p> Skilled in Web Design, HTML, JavaScript, Redux, ReactJS and Wordpress. Currently Frontend Web Developer based in Indonesia for Singapore Company.</p>
                        </Avatar>
                        <Footer />
                  </Container>
            </HomeWrapper>
      );
}

export default Home;
