import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
    background: linear-gradient(to right, #000, #333);
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FooterItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    padding: 20px;
`

const Footer: React.FC = () => {
    return (
          <FooterWrapper>
              <FooterItem>Designed with <span role='img' aria-label='heart' style={{margin: '0px 10px 0px 5px'}}>❤️ </span> in Indonesia</FooterItem>
          </FooterWrapper>
    );
  }

  export default Footer;
